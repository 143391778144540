import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        updateGeneralData(ctx, { payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/account/update/general-data', payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updatePassword(ctx, { payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/account/update/password', payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}