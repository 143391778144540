<template>
    
    <validation-observer ref="refForm">

        <b-form
            @submit.prevent="onSubmit"
            @reset.prevent="resetForm"
        >

            <b-row>
                <b-col md="12">
                    <b-card footer-tag="footer">

                        <b-row>

                            <!-- CURRENT PASSWORD -->
                            <b-col md="6">
                                <b-form-group
                                    label="CONTRASEÑA ACTUAL"
                                    label-for="account-old-password"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="contraseña actual"
                                        vid="PasswordOld"
                                        rules="required"
                                    >
                                        <b-input-group class="input-group-merge">
                                            <b-form-input
                                                id="account-old-password"
                                                v-model="formData.oldPasswordValue"
                                                name="old-password"
                                                :state="errors.length > 0 ? false : null"
                                                :type="formData.oldPasswordFieldType"
                                                placeholder="Contraseña actual"
                                            />
                                            <b-input-group-append is-text>
                                                <feather-icon
                                                    :icon="oldPasswordToggleIcon"
                                                    class="cursor-pointer"
                                                    @click="oldPasswordToggle"
                                                />
                                            </b-input-group-append>
                                        </b-input-group>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- NEW PASSWORD -->
                            <b-col md="6">
                                <b-form-group
                                    label-for="account-new-password"
                                    label="CONTRASEÑA NUEVA"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="contraseña nueva"
                                        vid="Password"
                                        rules="required|password"
                                    >
                                        <b-input-group class="input-group-merge">
                                            <b-form-input
                                                id="account-new-password"
                                                :state="errors.length > 0 ? false : null"
                                                v-model="formData.newPasswordValue"
                                                :type="formData.newPasswordFieldType"
                                                name="new-password"
                                                placeholder="********"
                                            />
                                            <b-input-group-append is-text>
                                                <feather-icon
                                                    :icon="newPasswordToggleIcon"
                                                    class="cursor-pointer"
                                                    @click="newPasswordToggle"
                                                />
                                            </b-input-group-append>
                                        </b-input-group>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- RETYPE NEW PASSWORD -->
                            <b-col md="6">
                                <b-form-group
                                    label-for="account-retype-new-password"
                                    label="REESCRIBA NUEVA CONTRASEÑA"
                                    class="mb-0"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="confirmar contraseña"
                                        rules="required|confirmed:Password"
                                    >
                                        <b-input-group class="input-group-merge">
                                            <b-form-input
                                                id="account-retype-new-password"
                                                v-model="formData.retypePasswordValue"
                                                :type="formData.retypePasswordFieldType"
                                                :state="errors.length > 0 ? false : null"
                                                name="retype-password"
                                                placeholder="********"
                                            />
                                            <b-input-group-append is-text>
                                                <feather-icon
                                                    :icon="retypePasswordToggleIcon"
                                                    class="cursor-pointer"
                                                    @click="retypePasswordToggle"
                                                />
                                            </b-input-group-append>
                                        </b-input-group>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                        </b-row>

                        <template #footer>

                            <!-- BUTTON SUBMIT -->
                            <b-button-loading 
                                text="ACTUALIZAR" 
                                type="submit"
                                :processing="processing">
                            </b-button-loading>
                        
                        </template>

                    </b-card>
                </b-col>
            </b-row>

        </b-form>

    </validation-observer>

</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import BButtonLoading from '@core/components/b-button-loading/BButtonLoading.vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { ref, computed, onUnmounted } from '@vue/composition-api';
    import { required, password, confirmed } from '@validations';
    import { useToast } from 'vue-toastification/composition';
    import accountStoreModule from './accountStoreModule';
    import Ripple from 'vue-ripple-directive';
    import store from '@/store';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            BButtonLoading,

            required,
            password,
            confirmed
        },
        directives: {
            Ripple
        },
        setup() {

            // USE TOAST
            const toast = useToast();

            // REFS
            const refForm = ref(null);

            const formData = ref({
                oldPasswordValue: null,
                newPasswordValue: null,
                retypePasswordValue: null,
                oldPasswordFieldType: 'password',
                newPasswordFieldType: 'password',
                retypePasswordFieldType: 'password'
            });

            const processing = ref(false);

            const ACCOUNT_APP_STORE_MODULE_NAME = 'app-account';

			// REGISTER MODULE
			if (!store.hasModule(ACCOUNT_APP_STORE_MODULE_NAME)) store.registerModule(ACCOUNT_APP_STORE_MODULE_NAME, accountStoreModule);

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(ACCOUNT_APP_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNT_APP_STORE_MODULE_NAME);
			});

            // COMPUTED
            const oldPasswordToggleIcon = computed(() => (formData.value.oldPasswordValue === 'password' ? 'EyeIcon' : 'EyeOffIcon'));

            const newPasswordToggleIcon = computed(() => (formData.value.newPasswordValue === 'password' ? 'EyeIcon' : 'EyeOffIcon'));

            const retypePasswordToggleIcon = computed(() => (formData.value.retypePasswordValue === 'password' ? 'EyeIcon' : 'EyeOffIcon'));

            // METHODS
            const oldPasswordToggle = () => {
                formData.value.oldPasswordFieldType = formData.value.oldPasswordFieldType === 'password' ? 'text' : 'password';
            };

            const newPasswordToggle = () => {
                formData.value.newPasswordFieldType = formData.value.newPasswordFieldType === 'password' ? 'text' : 'password';
            };

            const retypePasswordToggle = () => {
                formData.value.retypePasswordFieldType = formData.value.retypePasswordFieldType === 'password' ? 'text' : 'password';
            };

            const resetFormData = () => {
                formData.value = {
                    oldPasswordValue: null,
                    newPasswordValue: null,
                    retypePasswordValue: null,
                    oldPasswordFieldType: 'password',
                    newPasswordFieldType: 'password',
                    retypePasswordFieldType: 'password'
                }
            };

            const onSubmit = async () => {

                const isValid = await refForm.value.validate();

                if (!isValid)
                {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Complete los campos requeridos.',
                            icon: 'AlertTriangleIcon',
                            variant: 'warning'
                        }
                    });
                    
                    return;
                }

                processing.value = true;

                const { oldPasswordValue, newPasswordValue } = formData.value;

                const payload = {
                    oldPassword: oldPasswordValue,
                    password: newPasswordValue
                }

                store.dispatch('app-account/updatePassword', { payload })
                    .then( response => {

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success'
                            }
                        });

                        resetFormData();
                        refForm.value.reset();

                    })
                    .catch( (err) => {
                        let message = err.response.data.message ? err.response.data.message : 'Error al actualizar la contraseña.';
                        
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: message,
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });

                    })
                    .finally( () =>  processing.value = false);

            };

            return {
                // DATA
                processing,
                formData,

                // REFS
                refForm,

                // COMPUTED
                oldPasswordToggleIcon,
                newPasswordToggleIcon,
                retypePasswordToggleIcon,

                // METHODS
                oldPasswordToggle,
                newPasswordToggle,
                retypePasswordToggle,
                onSubmit
            }
        }
    }

</script>