<template>
    
    <validation-observer ref="refForm">

        <b-form
            @submit.prevent="onSubmit"
            @reset.prevent="resetForm"
        >

            <b-row>
                <b-col md="12">
                    <b-card footer-tag="footer">

                        <b-row>

                            <!-- NAME(S) -->
                            <b-col md="6" class="mb-2">
                                <label for="names"><strong>NOMBRE(S)</strong></label>
                                <validation-provider
                                    #default="{ errors }"
                                    rules="required"
                                    name="nombre(s)"
                                >
                                    <b-form-input
                                        id="names"
                                        v-model="formData.names"
                                        placeholder="Ingrese el nombre ..."
                                        :state="errors.length > 0 ? false : null"
                                        trim
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>

                            <!-- SURNAME(S) -->
                            <b-col md="6" class="mb-2">
                                <label for="surnames"><strong>APELLIDO(S)</strong></label>
                                <validation-provider
                                    #default="{ errors }"
                                    rules="required"
                                    name="apellido(s)"
                                >
                                    <b-form-input
                                        id="surnames"
                                        v-model="formData.surnames"
                                        placeholder="Ingrese el apellido ..."
                                        :state="errors.length > 0 ? false : null"
                                        trim
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>

                            <!-- MAIN EMAIL -->
                            <b-col md="6">
                                <label for="email"><strong>CORREO ELECTRÓNICO (<span class="text-primary">PRINCIPAL</span>)</strong></label>
                                <b-form-input
                                    id="email"
                                    v-model="formData.email"
                                    disabled
                                    readonly
                                />
                                <small class="text-primary">Este correo será utilizado para iniciar sesión, notificaciones principales y notificaciones secundarias.</small>
                            </b-col>

                            <!-- SECONDARY EMAIL -->
                            <b-col md="6">
                                <validation-provider
                                    #default="{ errors }"
                                    name="correo secundario"
                                    rules="email"
                                >
                                    <label for="email"><strong>CORREO ELECTRÓNICO (<span class="text-primary">SECUNDARIO</span>)</strong></label>
                                    <b-form-input
                                        id="secondary_email"
                                        v-model="formData.secondary_email"
                                        name="secondary_email"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Ingrese un correo secundario"
                                    />
                                    <small class="text-danger" v-if="errors.length">{{ errors[0] }} <br></small>
                                </validation-provider>
                                <small class="text-primary">Este correo será utilizado adicionalmente para notificaciones secundarias (Actualización de credenciales, Código de seguridad al iniciar sesión y Enlace para recuperar contraseña).</small>
                            </b-col>

                        </b-row>

                        <template #footer>

                            <!-- BUTTON SUBMIT -->
                            <b-button-loading 
                                text="ACTUALIZAR" 
                                type="submit"
                                :processing="processing">
                            </b-button-loading>

                        </template>

                    </b-card>
                </b-col>
            </b-row>

        </b-form>

    </validation-observer>

</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import BButtonLoading from '@core/components/b-button-loading/BButtonLoading.vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { ref, onUnmounted, onMounted } from '@vue/composition-api';
    import { useToast } from 'vue-toastification/composition';
    import accountStoreModule from './accountStoreModule';
    import { getUserData } from '@/auth/utils';
    import Ripple from 'vue-ripple-directive';
    import { required } from '@validations';
    import store from '@/store';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            BButtonLoading,

            required
        },
        directives: {
            Ripple
        },
        setup() {

            // USE TOAST
            const toast = useToast();

            // REFS
            const userData = ref(getUserData());
            const refForm = ref(null);

            const formData = ref({
                names: null,
                surnames: null,
                email: null,
                secondary_email: null
            });

            const processing = ref(false);

            const ACCOUNT_APP_STORE_MODULE_NAME = 'app-account';

			// REGISTER MODULE
			if (!store.hasModule(ACCOUNT_APP_STORE_MODULE_NAME)) store.registerModule(ACCOUNT_APP_STORE_MODULE_NAME, accountStoreModule);

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(ACCOUNT_APP_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNT_APP_STORE_MODULE_NAME);
			});

            onMounted(() => {
                setFormData();
            });

            // METHODS
            const setFormData = () => {
                const { names, surnames, email, secondary_email } = userData.value;

                formData.value = {
                    names,
                    surnames,
                    email,
                    secondary_email
                }
            };

            const onSubmit = async () => {

                const isValid = await refForm.value.validate();

                if (!isValid)
                {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Complete los campos requeridos.',
                            icon: 'AlertTriangleIcon',
                            variant: 'warning'
                        }
                    });
                    
                    return;
                }

                processing.value = true;

                const { names, surnames, secondary_email } = formData.value;

                const payload = {
                    names,
                    surnames,
                    secondary_email
                }

                store.dispatch('app-account/updateGeneralData', { payload })
                    .then( response => {

                        const { user } = response.data;

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success'
                            }
                        });

                        userData.value.names = user.names;
                        userData.value.surnames = user.surnames;
                        userData.value.fullName = user.fullName;
                        userData.value.secondary_email = user.secondary_email;

                        localStorage.setItem('userData', JSON.stringify(userData.value));

                        setTimeout(() => {
                            window.location.reload();
                        }, 2500);

                    })
                    .catch( (err) => {
                        let message = err.response.data.message ? err.response.data.message : 'Error al actualizar los datos generales.';
                        
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: message,
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });

                    })
                    .finally( () =>  processing.value = false);

            };

            return {
                // DATA
                processing,
                formData,

                // REFS
                refForm,

                // METHODS
                onSubmit
            }
        }
    }

</script>